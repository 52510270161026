"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_1 = require("react");
const Moment = require("moment");
const react_router_dom_1 = require("react-router-dom");
const semantic_ui_react_1 = require("semantic-ui-react");
const qs = require("query-string");
const format_1 = require("@helpers/format");
const plaid_1 = require("@actions/plaid");
const transactions_1 = require("@actions/transactions");
const transaction_tag_1 = require("@actions/transaction_tag");
const EditableDate_1 = require("@components/elements/EditableDate");
const EditableString_1 = require("@components/elements/EditableString");
const EditableCategory_1 = require("@components/elements/EditableCategory");
const EditablePrice_1 = require("@components/elements/EditablePrice");
const EditableTags_1 = require("@components/elements/EditableTags");
const EditableAsset_1 = require("@components/elements/EditableAsset");
const BalanceUpdater_1 = require("@components/elements/BalanceUpdater");
const Files_1 = require("@components/Transactions/Files");
const UserProvider_1 = require("@providers/UserProvider");
const TransactionsProvider_1 = require("@providers/TransactionsProvider");
const AssetsProvider_1 = require("@providers/AssetsProvider");
const ModalProvider_1 = require("@providers/ModalProvider");
const DetailsView = ({ data, useModal, utils, setVisibility, switchView, setDisableOnClickOutside, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    let history = (0, react_router_dom_1.useHistory)();
    const [tagsUpdated, setTagsUpdated] = (0, react_1.useState)(null);
    const [detailsUpdated, setDetailsUpdated] = (0, react_1.useState)(null);
    const [currentTags, setCurrentTags] = (0, react_1.useState)(null);
    const [balances, setBalances] = (0, react_1.useState)(null);
    const [showPendingInfo, setShowPendingInfo] = (0, react_1.useState)(false);
    const [pendingLoading, setPendingLoading] = (0, react_1.useState)(false);
    const _transactions = (0, react_1.useContext)(TransactionsProvider_1.TransactionsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _modal = (0, react_1.useContext)(ModalProvider_1.ModalContext);
    const transaction = _transactions.modalData.current;
    const [transactionOverride, setTransactionOverride] = (0, react_1.useState)({
        date: transaction.formatted_date || transaction.date,
        category_id: transaction.category_id,
        payee: transaction.payee,
        amount: transaction.amount,
        currency: transaction.currency,
        notes: transaction.notes,
        asset_id: transaction.asset_id,
        plaid_account_id: transaction.plaid_account_id,
    });
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            header: React.createElement("span", { className: "header-text-large" }, "Transaction Details"),
            leftButton: React.createElement(React.Fragment, null),
            middleButton: tagsUpdated || detailsUpdated ? (React.createElement(semantic_ui_react_1.Button, { color: 'orange', basic: true, onClick: () => {
                    setVisibility(false, true);
                } }, 'Cancel')) : (React.createElement(React.Fragment, null)),
        });
        return () => {
            useModal({
                header: React.createElement(React.Fragment, null),
                middleButton: React.createElement(React.Fragment, null),
                leftButton: React.createElement(React.Fragment, null),
            });
        };
    }, [tagsUpdated, detailsUpdated]);
    const enableTreatAsManual = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield utils._process(plaid_1.updateAccount)(transaction.plaid_account_id, {
            treat_as_manual: true,
        });
        _assets.updatePlaidAccount(transaction.plaid_account_id, {
            treat_as_manual: true,
        });
        setIsLoading(false);
    });
    // Setup
    (0, react_1.useEffect)(() => {
        useModal({
            rightButton: (React.createElement(semantic_ui_react_1.Button, { color: "orange", className: "no-border", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    // Update details
                    if (detailsUpdated) {
                        // Overwrite tags
                        const results = yield utils._process(transactions_1.updateTransaction)(transaction.id, transactionOverride);
                        if (!results.error) {
                            if (transactionOverride.payee) {
                                _transactions.addToMerchantNames(transactionOverride.payee);
                            }
                            utils._showToast({
                                message: 'Successfully updated transaction details',
                                type: 'success',
                            });
                        }
                    }
                    // Update tags separately so we can more easily update the UI
                    let tagResults = null;
                    if (tagsUpdated) {
                        // Overwrite tags
                        tagResults = yield utils._process(transaction_tag_1.overwriteTransactionTags)(transaction.id, currentTags);
                        if (!tagResults.error) {
                            utils._showToast({
                                message: 'Successfully updated transaction tags',
                                type: 'success',
                            });
                        }
                    }
                    if (!data.amendTransactions) {
                        setVisibility(false);
                    }
                    else {
                        let update = {};
                        if (detailsUpdated) {
                            update = Object.assign(Object.assign(Object.assign({}, update), { formatted_date: transactionOverride.date, display_name: transactionOverride.payee, display_notes: transactionOverride.notes }), transactionOverride);
                        }
                        if (tagsUpdated) {
                            update = Object.assign(Object.assign({}, update), { tags: (tagResults === null || tagResults === void 0 ? void 0 : tagResults.data) || [], tag_type: 'replace' });
                        }
                        data.amendTransactions({
                            update: Object.assign({ id: transaction.id }, update),
                        });
                        setVisibility(false);
                    }
                }) }, tagsUpdated || detailsUpdated ? 'Save Changes' : 'Close')),
        });
        return () => {
            useModal({
                rightButton: React.createElement(React.Fragment, null),
            });
        };
    }, [data, currentTags, tagsUpdated, detailsUpdated, transactionOverride]);
    /** Delete function has a confirmation component */
    const [showDeleteConfirmation, setShowDeleteConfirmation] = (0, react_1.useState)(false);
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        // Don't allow deleting if this is part of a group
        if (transaction.is_group || transaction.group_id !== null) {
            return;
        }
        // Don't allow deleting if this is part of a split
        if (!transaction.has_parent) {
            return;
        }
    }, [data, isLoading, showDeleteConfirmation]);
    return (React.createElement("div", { id: "transactions-modal-detail-view" },
        ((transaction.asset_id && !_assets.getAsset(transaction.asset_id)) ||
            (transaction.plaid_account_id &&
                !_assets.getPlaidAccount(transaction.plaid_account_id))) && (React.createElement(semantic_ui_react_1.Message, { warning: true },
            React.createElement(semantic_ui_react_1.Message.Header, { className: "mb-1rem" }, "Action Required: Account Not Found"),
            React.createElement(semantic_ui_react_1.Message.Content, null,
                React.createElement("p", null, "This transaction is associated with an account that had previously existed but has since been deleted."),
                React.createElement("p", null, "Something went wrong during this process and now this transaction (and perhaps others) need to be re-associated with a new account!"),
                React.createElement("p", null, "We recommend you click the button below to filter for all transactions from the same deleted account, and use our bulk-edit feature to re-assign them to a valid account."),
                React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", onClick: () => {
                        history.push({
                            pathname: '/transactions',
                            search: `?time=all&match=any&${transaction.asset_id ? 'asset' : 'account'}=${transaction.asset_id || transaction.plaid_account_id}`,
                        });
                        setVisibility(false);
                    } }, "View transactions from deleted account")))),
        transaction.is_pending && (React.createElement(semantic_ui_react_1.Message, { info: true }, !showPendingInfo ? (React.createElement(React.Fragment, null,
            React.createElement("p", null, "An imported transaction starts in a pending state, and can take 1 to 5 business days to post."),
            React.createElement("p", null,
                React.createElement("span", { className: "link clickable", onClick: () => {
                        setShowPendingInfo(true);
                    } },
                    React.createElement(semantic_ui_react_1.Icon, { name: "plus circle" }),
                    " Show more about pending transactions")))) : (React.createElement(React.Fragment, null,
            React.createElement("p", null, "A transaction starts as a pending transaction, then becomes posted once the funds have actually been transferred, as confirmed by your bank. It can take 1 to 5 business days for a transaction to post, and up to fourteen days in rare situations."),
            React.createElement("p", null, "Pending transactions are short-lived and frequently altered or removed by the institution before finally settling as a posted transaction."),
            React.createElement("p", null, "A pending transaction is not counted towards your totals & budgets."),
            React.createElement("p", null,
                React.createElement("b", null, "You cannot edit a pending transaction"),
                ", but you may see how a pending transaction's details have changed based on any Rules you have set up."))))),
        transaction.status === 'delete_pending' && (React.createElement("div", { className: "transaction-details" },
            React.createElement("div", { className: "detail-buttons" },
                React.createElement("h2", { className: "flex--space-between flex--align-top" },
                    "This transaction was marked as deleted by",
                    ' ',
                    transaction.institution_name,
                    ".",
                    React.createElement(semantic_ui_react_1.Popup, { position: "top right", trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle", color: "grey", position: "bottom" }), content: `An institution may sometimes update the details of a transaction, such as payee name or amount.
                  When this happens, sometimes we aren't able to match the changes to the original transaction,
                  so the original transaction is marked as deleted and we import a new transaction.` })),
                React.createElement("p", null, "We've removed this transaction from any budget or total calculations and we recommend that you delete this transaction for your records."),
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", icon: true, labelPosition: 'left', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            const results = yield utils._process(transactions_1.deleteTransaction)(transaction.id, {});
                            if (!results.error) {
                                utils._showToast({
                                    message: 'Successfully deleted transaction',
                                    type: 'success',
                                });
                                if (data.amendTransactions) {
                                    data.amendTransactions({
                                        remove: [transaction.id],
                                    });
                                    setVisibility(false, true);
                                }
                                else {
                                    setVisibility(false);
                                }
                            }
                        }) },
                        React.createElement(semantic_ui_react_1.Icon, { name: "checkmark" }),
                        "Delete transaction")),
                React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "grey", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            const results = yield utils._process(transactions_1.updateTransaction)(transaction.id, {
                                status: 'cleared',
                            });
                            if (!results.error) {
                                utils._showToast({
                                    message: 'Successfully updated transaction',
                                    type: 'success',
                                });
                                if (data.amendTransactions) {
                                    data.amendTransactions({
                                        update: {
                                            id: transaction.id,
                                            status: 'cleared',
                                        },
                                    });
                                    setVisibility(false, true);
                                }
                                else {
                                    setVisibility(false);
                                }
                            }
                        }) }, "Keep transaction (not recommended)"))))),
        React.createElement("div", { className: "transaction-details" },
            React.createElement("h3", { className: "flex--space-between" },
                "Details",
                !transaction.is_pending &&
                    transaction.recurring_type !== 'cleared' &&
                    transaction.status === 'cleared' && (React.createElement("span", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle", color: "green" }),
                    React.createElement("span", { className: "color--green" }, "Reviewed"))),
                !transaction.is_pending && transaction.status === 'uncleared' && (React.createElement("span", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "check circle outline", className: "color--grey" }),
                    React.createElement("span", { className: "color--grey" }, "Unreviewed"))),
                !transaction.is_pending && transaction.recurring_type === 'cleared' && (React.createElement("span", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "history", color: "green" }),
                    React.createElement("span", { className: "color--green" }, "Recurring"))),
                transaction.is_pending && (React.createElement("span", { className: "display--flex" },
                    React.createElement(semantic_ui_react_1.Icon, { name: "clock", className: "color--grey" }),
                    React.createElement("span", { className: "color--grey" }, "Pending")))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Date",
                    transaction.is_pending && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle" }), position: "right center" }, "The dates for pending transactions are more than likely to change once they are posted, therefore they are not editable in this state."))),
                React.createElement(semantic_ui_react_1.Form, null,
                    React.createElement(EditableDate_1.default, { identifier: `transaction-date`, firstValue: transactionOverride.date, location: 'modal', format: 'month_day_year', state: 'Editing', disabled: transaction.is_pending, onSave: date => {
                            setTransactionOverride(Object.assign(Object.assign({}, transactionOverride), { date: date }));
                            setDetailsUpdated(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Category",
                    transaction.recurring_type == 'cleared' && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle" }), position: "right center" }, "This category can be updated from the Recurring Items page as it will affect the categories of all transactions linked to this recurring item."))),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableCategory_1.default, { identifier: `transaction-category-modal`, firstValue: transactionOverride.category_id, disabled: transaction.recurring_type == 'cleared', location: 'modal', state: 'Editing', onShowConfirmation: () => {
                            setDisableOnClickOutside(true);
                        }, onSave: category_id => {
                            setTransactionOverride(Object.assign(Object.assign({}, transactionOverride), { category_id: category_id }));
                            setDetailsUpdated(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Payee Name",
                    transaction.recurring_type == 'cleared' && (React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle" }), position: "right center" }, "This payee name can be updated from the Recurring Items page as it will affect the payee name of all transactions linked to this recurring item."))),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableString_1.default, { identifier: `transaction-payee`, firstValue: transactionOverride.payee, location: 'modal', disabled: transaction.recurring_type == 'cleared', autosuggest: true, state: 'Editing', shouldSaveOnChange: true, onSave: payee => {
                            setTransactionOverride(Object.assign(Object.assign({}, transactionOverride), { payee: payee }));
                            setDetailsUpdated(true);
                        } }))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Amount",
                    !!transaction.plaid_account_id &&
                        !((_a = _assets.getPlaidAccount(transaction.plaid_account_id)) === null || _a === void 0 ? void 0 : _a.treat_as_manual) && (React.createElement(semantic_ui_react_1.Popup, { onOpen: () => {
                            setDisableOnClickOutside(true);
                        }, onClose: () => {
                            setDisableOnClickOutside(false);
                        }, onUnmount: () => {
                            setDisableOnClickOutside(false);
                        }, hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }), position: "right center" },
                        React.createElement("p", null, "Need to update the amount on this imported transaction? You'll first need to enable \"Allow modifications to transactions\" under the Accounts page for this account, or click the button below."),
                        React.createElement(semantic_ui_react_1.Button, { onClick: enableTreatAsManual, loading: isLoading, disabled: isLoading, fluid: true, size: "mini" },
                            "Allow modifications to transactions for",
                            ' ',
                            transaction.plaid_account_name)))),
                !transaction.zabo_account_id &&
                    !transaction.is_group &&
                    !transaction.has_parent &&
                    (!transaction.plaid_account_id ||
                        ((_b = _assets.getPlaidAccount(transaction.plaid_account_id)) === null || _b === void 0 ? void 0 : _b.treat_as_manual)) ? (React.createElement(semantic_ui_react_1.Form, { widths: "equal", className: "mb-05rem" },
                    React.createElement(EditablePrice_1.default, { identifier: `transaction-amount`, amount: transactionOverride.amount, showSignLabel: true, currency: transactionOverride.currency, location: 'modal', state: 'Editing', saveOnChange: true, onSave: (amount, currency) => {
                            setTransactionOverride(Object.assign(Object.assign({}, transactionOverride), { amount,
                                currency }));
                            setDetailsUpdated(true);
                        } }))) : (React.createElement("span", null, (0, format_1.toPrice)(_user.settings['show_debits_as_negative']
                    ? -1 * transaction.amount
                    : transaction.amount, transaction.currency))),
                transaction.currency !== _user.primaryCurrency ? (React.createElement("p", { className: "monospace" },
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "info circle", color: "grey", fitted: true }) },
                        "Converted to your primary currency (",
                        _user.primaryCurrency,
                        ") using the exchange rate from",
                        ' ',
                        Moment(transaction.date, 'YYYY-MM-DD').format(_user.getFormat('month_day_year')),
                        ", or the exchange rate from the closest date if the transaction date is too far in the past or in the future. This converted value is used in calculations of totals."),
                    ' ',
                    "Converted to ",
                    (0, format_1.toPrice)(transaction.to_base, _user.primaryCurrency),
                    ' ',
                    "at ",
                    (0, format_1.toPrice)(1, transaction.currency),
                    " =",
                    ' ',
                    (0, format_1.toPrice)(parseFloat(transaction.to_base) /
                        parseFloat(transaction.amount), _user.primaryCurrency, 0.0001))) : (React.createElement(React.Fragment, null))),
            React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Notes",
                    transaction.recurring_type == 'cleared' && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "question circle" }), position: "right center" }, "These notes can be updated from the Recurring Items page as it will affect the notes of all transactions linked to this recurring item."))),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableString_1.default, { identifier: `transaction-notes`, firstValue: transactionOverride.notes || '', location: 'modal', disabled: transaction.recurring_type == 'cleared', state: 'Editing', shouldSaveOnChange: true, onSave: value => {
                            setTransactionOverride(Object.assign(Object.assign({}, transactionOverride), { notes: value }));
                            setDetailsUpdated(true);
                        } }))),
            !transaction.is_pending && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction tag(s)"),
                React.createElement(semantic_ui_react_1.Form, { widths: "equal" },
                    React.createElement(EditableTags_1.default, { onChange: value => {
                            setCurrentTags(value);
                            setTagsUpdated(true);
                            setDisableOnClickOutside(true);
                        }, initialValue: ((_c = transaction === null || transaction === void 0 ? void 0 : transaction.tags) === null || _c === void 0 ? void 0 : _c.map(o => o.id)) || [] })))),
            transaction.source == 'plaid' && transaction.original_name && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Original Name"),
                React.createElement("span", null, transaction.original_name))),
            !transaction.is_group && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null,
                    "Paid from",
                    !!transaction.plaid_account_id &&
                        !((_d = _assets.getPlaidAccount(transaction.plaid_account_id)) === null || _d === void 0 ? void 0 : _d.treat_as_manual) && (React.createElement(semantic_ui_react_1.Popup, { onOpen: () => {
                            setDisableOnClickOutside(true);
                        }, onClose: () => {
                            setDisableOnClickOutside(false);
                        }, onUnmount: () => {
                            setDisableOnClickOutside(false);
                        }, hoverable: true, trigger: React.createElement(semantic_ui_react_1.Icon, { className: "ml-05rem", name: "info circle" }), position: "right center" },
                        React.createElement("p", null, "Need to update the account on this imported transaction? You'll first need to enable \"Allow modifications to transactions\" under the Accounts page for this account, or click the button below."),
                        React.createElement(semantic_ui_react_1.Button, { onClick: enableTreatAsManual, loading: isLoading, disabled: isLoading, fluid: true, size: "mini" },
                            "Allow modifications to transactions for",
                            ' ',
                            transaction.plaid_account_name)))),
                !!transaction.plaid_account_id &&
                    !((_e = _assets.getPlaidAccount(transaction.plaid_account_id)) === null || _e === void 0 ? void 0 : _e.treat_as_manual) ? (React.createElement("span", null,
                    transaction.account_display_name,
                    ' ',
                    transaction.plaid_account_mask && (React.createElement(React.Fragment, null,
                        "(",
                        transaction.plaid_account_mask,
                        ")")))) : (React.createElement(EditableAsset_1.default, { defaultSelection: {
                        source: transactionOverride.plaid_account_id
                            ? 'plaid'
                            : transactionOverride.asset_id
                                ? 'manual'
                                : null,
                        id: transactionOverride.plaid_account_id ||
                            transactionOverride.asset_id ||
                            -1,
                    }, onSave: value => {
                        setTransactionOverride(Object.assign(Object.assign({}, transactionOverride), { [value.source == 'plaid' ? 'plaid_account_id' : 'asset_id']: value.id > -1 ? value.id : null, [value.source == 'plaid'
                                ? 'asset_id'
                                : 'plaid_account_id']: null }));
                        setDetailsUpdated(true);
                    } })))),
            transaction.source && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction Source"),
                React.createElement("span", null, transaction.source == 'plaid' ? (React.createElement(React.Fragment, null,
                    "Imported from Plaid (",
                    React.createElement("a", { onClick: () => {
                            switchView('VIEW_PLAID_METADATA');
                        }, className: "link clickable" }, "view metadata"),
                    ")")) : transaction.source == 'csv' ? ('Imported via CSV') : transaction.source == 'api' ? ('Imported via API') : transaction.source == 'rule' ? ('Created from rule') : transaction.source == 'merge' ? ('Created from merge') : transaction.source == 'recurring' ? ('Created from recurring' // manual, user
                ) : ('Created manually by user')),
                transaction.plaid_external_id && (React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Button, { negative: true, fluid: true, basic: true, className: "mt-05rem", size: "mini", onClick: () => {
                            setDisableOnClickOutside(true);
                            history.push({
                                search: qs.stringify(Object.assign(Object.assign({}, qs.parse(history.location.search)), { sync_support: true, sync_account: transaction.plaid_account_id, sync_transaction: transaction.plaid_external_id })),
                            });
                        } }, "Report faulty data"), inverted: true, size: "small" }, "Was this transaction imported with faulty data, such as incorrect notation, currency or name? Click here to open a bank syncing support ticket!")))),
            transaction.quantity && transaction.price && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Price Breakdown"),
                React.createElement("span", null,
                    Math.abs(parseFloat(transaction.quantity)),
                    " at",
                    ' ',
                    (0, format_1.toPrice)(transaction.price, transaction.currency),
                    " each"))),
            transaction.fees && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Fees"),
                React.createElement("span", null, (0, format_1.toPrice)(transaction.fees, transaction.currency)))),
            (transaction.status == 'cleared' ||
                transaction.status == 'uncleared') && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Status"),
                React.createElement(semantic_ui_react_1.Dropdown, { className: "category-dropdown p-category-modal", selection: true, fluid: true, defaultValue: transaction.status, options: [
                        {
                            key: 'cleared',
                            text: 'Reviewed',
                            value: 'cleared',
                        },
                        {
                            key: 'uncleared',
                            text: 'Unreviewed',
                            value: 'uncleared',
                        },
                    ], onChange: (e, { value }) => {
                        const newValue = Object.assign(Object.assign({}, transactionOverride), { status: value });
                        setTransactionOverride(newValue);
                        setDetailsUpdated(true);
                    } }))),
            !transaction.is_pending &&
                (transaction.has_parent ||
                    transaction.recurring_id ||
                    transaction.is_group) && (React.createElement("div", { className: "transaction-detail" },
                React.createElement("label", null, "Transaction type(s)"),
                React.createElement("div", null,
                    transaction.has_parent && (React.createElement("span", null,
                        "Split",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), position: "right center" }, "This transaction was created from splitting another transaction. For more details, click on 'Edit Split Transaction' below."))),
                    transaction.is_group && (React.createElement("span", null,
                        "Grouped",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), position: "right center" }, "This transaction is the summation of 2 or more transactions. For more details, click on 'Edit Transaction Group' below."))),
                    transaction.recurring_id &&
                        transaction.recurring_type == 'cleared' && (React.createElement("span", null,
                        "Recurring",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), position: "right center" },
                            "This transaction is the payment for a recurring",
                            ' ',
                            transaction.is_income ? 'income' : 'expense',
                            ". For more details, click on 'Edit Recurring Item' below."))),
                    transaction.recurring_id &&
                        transaction.recurring_type == 'suggested' && (React.createElement("span", null,
                        "Suggested Recurring",
                        ' ',
                        React.createElement(semantic_ui_react_1.Popup, { inverted: true, trigger: React.createElement(semantic_ui_react_1.Icon, { name: "question circle" }), position: "right center" },
                            "This transaction is the payment for a potential recurring ",
                            transaction.is_income ? 'income' : 'expense',
                            ". You should either accept or reject this suggestion from this detail pane or from the Recurring Items page."))))))),
        transaction.is_pending && (React.createElement(semantic_ui_react_1.Popup, { position: 'top center', trigger: React.createElement(semantic_ui_react_1.Button, { fluid: true, loading: pendingLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setPendingLoading(true);
                    // Set as deleted
                    const results = yield utils._process(transactions_1.updateTransaction)(transaction.id, {
                        status: 'deleted',
                    });
                    if (!results.error) {
                        if (data.amendTransactions) {
                            data.amendTransactions({
                                remove: [transaction.id],
                            });
                        }
                        setVisibility(false);
                        utils._showToast({
                            type: 'success',
                            autoDismissTimeout: 5000,
                            message: "Transaction removed. If it doesn't appear within the next 3 business days, please open a support ticket",
                        });
                    }
                }) },
                "This transaction is already posted ",
                React.createElement(semantic_ui_react_1.Icon, { name: "question circle" })) }, "Is your bank portal showing that this transaction should already be posted? If so, this might be a syncing issue. Click this button to report this and we'll work on resolving this on our end.")),
        !transaction.is_pending && (React.createElement(Files_1.default, { amendTransactions: data.amendTransactions, files: transaction.files || [], setDisableOnClickOutside: setDisableOnClickOutside, transaction_id: transaction.id })),
        !transaction.is_pending && transaction.recurring_type === 'suggested' && (React.createElement(semantic_ui_react_1.Message, { info: true, className: "mt-0 mb-1rem" },
            React.createElement(semantic_ui_react_1.Message.Header, null, "We think this is a recurring item"),
            React.createElement(semantic_ui_react_1.Message.Content, { className: "mt-1rem" },
                "Based on the transaction name and amount, we were able to match this transaction to similar ones that happened a month ago. If this is a regularly occurring transaction, it can be tracked under the Recurring Items tab.",
                React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mt-1rem", color: "orange", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        _modal.data.current.recurring_id = transaction.recurring_id;
                        _modal.data.current.transaction = transaction;
                        switchView('SUGGESTED_RECURRING', null);
                    }) }, "Manage recurring item")))),
        !transaction.is_pending && (React.createElement("div", { className: "detail-buttons" },
            React.createElement("h2", null, "I want to.."),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", disabled: transaction.is_group ||
                    !!transaction.group_id ||
                    (transaction.recurring_id &&
                        transaction.recurring_type == 'cleared'), color: "orange", onClick: () => {
                    switchView('SPLIT_VIEW');
                } }, transaction.has_parent
                ? `Edit Split Transaction`
                : `Split Transaction`),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", disabled: !!transaction.group_id ||
                    transaction.recurring_type == 'suggested', color: "orange", onClick: () => {
                    switchView('RECURRING_VIEW');
                } }, transaction.recurring_type == 'cleared'
                ? 'Edit Recurring Item'
                : `Convert to Recurring Item`),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", disabled: !!transaction.recurring_id, color: "orange", onClick: () => {
                    switchView('GROUP_VIEW');
                } }, transaction.is_group || transaction.group_id
                ? 'Edit transaction group'
                : 'Create transaction group'),
            _assets.assets.length > 0 && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", disabled: transaction.is_group ||
                    !!transaction.group_id ||
                    transaction.recurring_type == 'cleared', color: "orange", onClick: () => {
                    switchView('CREATE_HALF_TRANSFER');
                } }, "Create a transfer")),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", color: "orange", onClick: () => {
                    var _a;
                    _modal.data.current = {
                        transaction: transaction,
                        rule: {
                            // Rule
                            criteria_payee_name: transaction.original_name || transaction.payee,
                            criteria_payee_name_match: 'contain',
                            criteria_amount: transaction.amount,
                            criteria_amount_currency: transaction.currency,
                            criteria_amount_match: 'exactly',
                            criteria_asset_id: transaction.asset_id
                                ? transaction.asset_id
                                : null,
                            criteria_plaid_account_id: transaction.plaid_account_id
                                ? transaction.plaid_account_id
                                : null,
                            // Effect
                            payee_name: transaction.original_name &&
                                transaction.original_name !== transaction.payee
                                ? transaction.payee
                                : '',
                            notes: transaction.notes,
                            tags: ((_a = transaction.tags) === null || _a === void 0 ? void 0 : _a.length) > 0 ? transaction.tags : null,
                        },
                        previous: 'DETAILS_VIEW',
                        previous_text: 'Transaction Details',
                    };
                    switchView('ADD_RULE');
                } }, "Create a rule"),
            transaction.recurring_id && (React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", onClick: () => {
                    setVisibility(false);
                    history.push({
                        pathname: '/transactions',
                        search: `?match=any&recurring=${transaction.recurring_id}&time=all`,
                    });
                } }, "View all recurring transactions")),
            React.createElement(semantic_ui_react_1.Button, { fluid: true, size: "small", className: "mb-1rem", onClick: () => {
                    switchView('VIEW_TRANSACTION_LOG');
                } }, "View change history"),
            !transaction.is_group && (React.createElement(semantic_ui_react_1.Button, { size: "small", color: 'red', fluid: true, onClick: () => {
                    setShowDeleteConfirmation(true);
                    setDisableOnClickOutside(true);
                }, loading: isLoading }, "Delete this transaction")))),
        React.createElement(semantic_ui_react_1.Modal, { open: showDeleteConfirmation, size: 'tiny' },
            React.createElement(semantic_ui_react_1.Modal.Header, null, "Confirm deletion"),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement("p", null,
                    "Are you sure you want to delete this transaction",
                    ((_f = transaction.files) === null || _f === void 0 ? void 0 : _f.length) > 0
                        ? ` including ${(_g = transaction.files) === null || _g === void 0 ? void 0 : _g.length} attachment${((_h = transaction.files) === null || _h === void 0 ? void 0 : _h.length) > 1 ? 's' : ''}`
                        : '',
                    "?")),
            React.createElement(semantic_ui_react_1.Modal.Actions, null,
                React.createElement(semantic_ui_react_1.Button, { basic: true, loading: isLoading, color: "orange", onClick: () => {
                        setShowDeleteConfirmation(false);
                    } }, "No, Cancel"),
                React.createElement(semantic_ui_react_1.Button, { negative: true, loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _j, _k, _l, _m;
                        setIsLoading(true);
                        const results = yield utils._process(transactions_1.deleteTransaction)(transaction.id, {});
                        if (!results.error) {
                            if (((_j = results.data) === null || _j === void 0 ? void 0 : _j.asset_update) &&
                                ((_k = results.data) === null || _k === void 0 ? void 0 : _k.asset_update.hasOwnProperty('balance')) &&
                                ((_l = results.data) === null || _l === void 0 ? void 0 : _l.asset_update.hasOwnProperty('currency')) &&
                                ((_m = results.data) === null || _m === void 0 ? void 0 : _m.asset_update.hasOwnProperty('name'))) {
                                setBalances([results.data.asset_update]);
                            }
                            else {
                                // Show success message
                                utils._showToast({
                                    message: 'Successfully deleted transaction',
                                    type: 'success',
                                });
                            }
                            // Delete
                            if (data.amendTransactions) {
                                data.amendTransactions({
                                    remove: [transaction.id],
                                });
                                setVisibility(false, true);
                            }
                            else {
                                setVisibility(false);
                            }
                        }
                        setIsLoading(false);
                        setShowDeleteConfirmation(false);
                    }) }, "Yes, Delete"))),
        React.createElement(BalanceUpdater_1.default, { _showToast: utils._showToast, balances: balances })));
};
exports.default = DetailsView;
