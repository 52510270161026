"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *  EditableDate.tsx
 */
const react_datepicker_1 = require("react-datepicker");
require("react-datepicker/dist/react-datepicker.css");
const React = require("react");
const Moment = require("moment");
const react_1 = require("react");
// Helpers
const UserProvider_1 = require("@providers/UserProvider");
const locale_1 = require("date-fns/esm/locale");
var State;
(function (State) {
    /** Default display */
    State["Default"] = "Default";
    /** Is currently being edited */
    State["Editing"] = "Editing";
    /** Is read only; not editable */
    State["ReadOnly"] = "ReadOnly";
})(State || (State = {}));
const EditableDate = ({ 
/** Unique identifier */
identifier = null, 
/** Initialized value */
firstValue = null, 
/** Handler to be called on save */
onSave = x => { }, 
/** Starting state */
state = 'Default', 
/** Whether or not date should start opened, default false */
autoFocus = false, 
/** Used inline in table or in modal */
location = null, showError = false, 
/** Show month/year picker only */
monthPicker = false, yearPicker = false, 
/** Date format for date picker input */
format, jumpToEnd = null, placeholder = null, maxDate = null, disabled = false, }) => {
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const [currentState, setCurrentState] = (0, react_1.useState)(state);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(firstValue);
    (0, react_1.useEffect)(() => {
        if (_user.settings['week_starts_on'] == 'monday') {
            (0, react_datepicker_1.registerLocale)('en-GB', locale_1.enGB);
        }
    }, []);
    (0, react_1.useEffect)(() => {
        setCurrentState(state);
        setCurrentValue(firstValue);
    }, [identifier, state, firstValue]);
    return (React.createElement("div", { className: `g-editable-text ${location == 'inline' ? 'editable-input-mui-inline' : ''} ${currentState === State.Editing && location.indexOf('inline') > -1
            ? 'selected'
            : ''}` },
        currentState === State.ReadOnly && (React.createElement("div", { className: "readonly" },
            React.createElement("div", null, Moment(currentValue).format(_user.getFormat(format))))),
        currentState === State.Default && (React.createElement("div", { className: "default-state", onMouseLeave: () => {
                setCurrentState(State.Default);
            }, onClick: () => {
                setCurrentState(State.Editing);
            } }, Moment(currentValue).format(_user.getFormat(format)))),
        currentState === State.Editing && (React.createElement(react_datepicker_1.default, Object.assign({}, (_user.settings['week_starts_on'] == 'monday'
            ? { locale: 'en-GB' }
            : {}), { disabled: disabled, selected: currentValue !== null
                ? Moment(currentValue, 'YYYY-MM-DD').toDate()
                : null, openToDate: currentValue
                ? Moment(currentValue, 'YYYY-MM-DD').toDate()
                : new Date(), showYearDropdown: yearPicker, yearDropdownItemNumber: 2, showMonthYearPicker: monthPicker, dateFormat: _user
                .getFormat(format)
                .replace(/ddd/g, 'eee')
                .replace(/D/g, 'd')
                .replace(/Y/g, 'y'), onBlur: () => {
                if (location === 'inline' &&
                    identifier !== 'new-tx-date' &&
                    identifier !== 'recurring-active-period') {
                    setCurrentState(State.Default);
                }
                else {
                    if (currentValue === null || !Moment(currentValue).isValid()) {
                        onSave(null);
                    }
                    else {
                        onSave(Moment(currentValue).format('YYYY-MM-DD'));
                    }
                }
            }, maxDate: maxDate || undefined, isClearable: identifier === 'recurring-active-period', onChange: date => {
                if (date === null) {
                    setCurrentState(location === 'modal' ||
                        identifier === 'new-tx-date' ||
                        identifier === 'recurring-active-period'
                        ? State.Editing
                        : State.Default);
                    setCurrentValue(null);
                    // Save value
                    onSave(null);
                }
                else if (Moment(date).isValid()) {
                    setCurrentState(location === 'modal' ||
                        identifier === 'new-tx-date' ||
                        identifier === 'recurring-active-period'
                        ? State.Editing
                        : State.Default);
                    setCurrentValue(jumpToEnd
                        ? Moment(date)
                            .endOf('month')
                            .format('YYYY-MM-DD')
                        : Moment(date).format('YYYY-MM-DD'));
                    // Save value
                    onSave(jumpToEnd
                        ? Moment(date)
                            .endOf('month')
                            .format('YYYY-MM-DD')
                        : Moment(date).format('YYYY-MM-DD'));
                }
            }, placeholderText: placeholder || 'Select date', className: `datepicker ${disabled ? 'disabled' : ''} p-datepicker-${location} ${showError ? 'error-datepicker' : ''}`, autoFocus: autoFocus })))));
};
exports.default = EditableDate;
