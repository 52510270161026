"use strict";
/**
 * The main layout wrapping all containers. Contains navigation header and other globally available elements.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_router_dom_1 = require("react-router-dom");
const Moment = require("moment");
const React = require("react");
const react_1 = require("react");
const semantic_ui_react_1 = require("semantic-ui-react");
const account_1 = require("@/actions/account");
const Nav_1 = require("../components/global/Nav");
const Footer_1 = require("../components/global/Footer");
const BillingProvider_1 = require("@/providers/BillingProvider");
const UserProvider_1 = require("@/providers/UserProvider");
const CategoriesProvider_1 = require("@/providers/CategoriesProvider");
const TagsProvider_1 = require("@/providers/TagsProvider");
const AssetsProvider_1 = require("@/providers/AssetsProvider");
const RecurringProvider_1 = require("@/providers/RecurringProvider");
const Layout = ({ children }) => {
    var _a;
    const _billing = (0, react_1.useContext)(BillingProvider_1.BillingContext);
    const _user = (0, react_1.useContext)(UserProvider_1.UserContext);
    const _categories = (0, react_1.useContext)(CategoriesProvider_1.CategoriesContext);
    const _tags = (0, react_1.useContext)(TagsProvider_1.TagsContext);
    const _assets = (0, react_1.useContext)(AssetsProvider_1.AssetsContext);
    const _recurring = (0, react_1.useContext)(RecurringProvider_1.RecurringContext);
    const [isReady, setIsReady] = (0, react_1.useState)(false);
    const [showError, setShowError] = (0, react_1.useState)(false);
    const errorTimeout = (0, react_1.useRef)(null);
    const errorInterval = (0, react_1.useRef)(null);
    const [timer, setTimer] = (0, react_1.useState)(10);
    const [showNotice, setShowNotice] = (0, react_1.useState)(localStorage.getItem('_lm_show_maintenance_notice_20250105') !== 'false');
    (0, react_1.useEffect)(() => {
        const timeoutId = setTimeout(() => {
            setShowError(true);
        }, 15 * 1000);
        errorTimeout.current = timeoutId;
        return () => {
            clearTimeout(errorTimeout.current);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        if (!timer) {
            clearInterval(errorInterval.current);
            window.location.reload();
            return;
        }
        if (!showError)
            return;
        const intervalId = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);
        errorInterval.current = intervalId;
        return () => clearInterval(errorInterval.current);
    }, [showError, timer]);
    // First, load providers which will load respective data.
    (0, react_1.useEffect)(() => {
        if (!isReady) {
            setIsReady(_billing.isReady &&
                _user.hasFetchedSettings &&
                _user.hasFetchedAccountSettings &&
                _categories.isReady &&
                _tags.isReady &&
                _assets.isReady &&
                _recurring.isReady);
        }
    }, [_billing, _user, _categories, _tags, _assets, _recurring, isReady]);
    (0, react_1.useEffect)(() => {
        if (isReady) {
            clearTimeout(errorTimeout.current);
            clearInterval(errorInterval.current);
        }
    }, [isReady]);
    if (isReady) {
        return (React.createElement("div", null,
            !_user.verifiedEmail &&
                Moment().diff(Moment(_user.joinDate), 'hours') > 3 && (React.createElement("div", { className: "fixed-banner verify" },
                React.createElement(react_router_dom_1.Link, { to: "/profile" },
                    "You have not yet verified your email address: ",
                    _user.email,
                    ". Please verify it to continue using Lunch Money!",
                    ' ',
                    React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" })))),
            _billing.status == 'trialing' &&
                !((_a = _billing.subscription) === null || _a === void 0 ? void 0 : _a.payment) &&
                !_user.isDemo &&
                _user.isAdmin && (React.createElement("div", { className: "fixed-banner billing" },
                React.createElement(react_router_dom_1.Link, { to: "/billing" },
                    "Your free trial ends on ",
                    _billing.trialEnd,
                    ". Enter your billing information to avoid interruption in service!",
                    ' ',
                    React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" })))),
            !_billing.isActive && (React.createElement("div", { className: "fixed-banner error" },
                React.createElement(react_router_dom_1.Link, { to: "/billing" },
                    "There is an issue with your billing. Please add your billing information before continuing.",
                    React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" })))),
            _billing.isPaused && (React.createElement("div", { className: "fixed-banner warning" },
                React.createElement(react_router_dom_1.Link, { to: "/billing" },
                    "You've put your subscription on pause. You won't be able to continue without starting a new subscription.",
                    React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" })))),
            _user.isDemo && window.location.href.indexOf('demo') == -1 && (React.createElement("div", { className: "fixed-banner demo" },
                React.createElement(react_router_dom_1.Link, { to: "#switch" },
                    React.createElement(semantic_ui_react_1.Popup, { trigger: React.createElement(semantic_ui_react_1.Icon, { name: "x", className: "mr-1rem clickable", onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                e.preventDefault();
                                yield (0, account_1.removeDemo)();
                                yield _user.fetchUserDetails();
                            }) }), inverted: true, size: "mini" }, "Dismiss"),
                    "Done with the demo? Click here to create your own budget!",
                    React.createElement(semantic_ui_react_1.Icon, { name: "long arrow alternate right" })))),
            React.createElement(Nav_1.default, { hasBanner: !_billing.isActive ||
                    _billing.isPaused ||
                    _billing.status == 'trialing' ||
                    _user.isDemo }),
            React.createElement(React.Fragment, null, children),
            React.createElement(Footer_1.default, null)));
    }
    else if (!isReady && window.location.href.indexOf('logout') == -1) {
        return (React.createElement("div", { className: "g-loading" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "content" },
                    React.createElement("div", { id: "logo-container" },
                        React.createElement("div", { id: "logo", className: showError ? 'empty-logo' : '' }),
                        React.createElement("div", { id: "shadow" })),
                    React.createElement(semantic_ui_react_1.Header, { as: "h2", className: "center-align" }, showError
                        ? `We're having trouble fetching your data!`
                        : 'Please wait while we prepare the goods!'),
                    !showError && (React.createElement(semantic_ui_react_1.Progress, { active: true, percent: (_billing.isReady ? 16 : 0) +
                            (_user.hasFetchedSettings ? 14 : 0) +
                            (_user.hasFetchedAccountSettings ? 14 : 0) +
                            (_categories.isReady ? 14 : 0) +
                            (_tags.isReady ? 14 : 0) +
                            (_assets.isReady ? 14 : 0) +
                            (_recurring.isReady ? 14 : 0) })),
                    showError && (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "done" },
                            "Please check your internet connection or contact us at",
                            ' ',
                            React.createElement("a", { href: "mailto:support@lunchmoney.app" }, "support@lunchmoney.app"),
                            ' ',
                            "for assistance if this error persists."),
                        React.createElement(semantic_ui_react_1.Button, { onClick: () => {
                                clearInterval(errorInterval.current);
                                clearTimeout(errorTimeout.current);
                                window.location.reload();
                            }, size: "small", fluid: true, color: "orange" }, errorTimeout.current
                            ? `Retrying in ${timer} seconds..`
                            : 'Retry')))))));
    }
};
exports.default = Layout;
